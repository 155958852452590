import LinkComponent from '../LinkComponent/LinkComponent'
import './QuantumEducatorCard.scss'

export type QuantumEducator = {
  /** First name */
  firstName: string
  /** Last name */
  lastName: string
  /** Institution or company */
  institution: string
  /** URL to image */
  image: string
  /** Links to profiles/social */
  links?: {
    /** PennyLane profile */
    profile?: string
    /** Google Scholar profile */
    googleScholar?: string
    /** LinkedIn profile */
    linkedin?: string
    /** Other profile/website */
    other?: string
  }
}

export default function QuantumEducatorCard({
  firstName,
  lastName,
  institution,
  image,
  links,
}: QuantumEducator) {
  const hasOneOrMoreLinks = links && Object.values(links).some(Boolean)
  const fullName = `${firstName} ${lastName}`.trim()

  return (
    <div className="QuantumEducatorCard" data-testid="quantum-educator-card">
      <div className="QuantumEducatorCard__image">
        <img
          src={image}
          alt={fullName}
          data-testid="quantum-educator-card-image"
        />
      </div>

      <div className="QuantumEducatorCard__info">
        <h3 className="QuantumEducatorCard__title">{fullName}</h3>
        <p className="QuantumEducatorCard__institution">{institution}</p>
        {hasOneOrMoreLinks && (
          <ul
            className="QuantumEducatorCard__links"
            data-testid="quantum-educators-links"
          >
            {links?.profile && (
              <li>
                <LinkComponent
                  href={links.profile}
                  aria-label="PennyLane profile"
                  testId="pennylane-profile"
                >
                  <img
                    width={24}
                    height={24}
                    src="https://assets.cloud.pennylane.ai/pennylane_website/icons/pl_logo.png"
                    alt=""
                  />
                </LinkComponent>
              </li>
            )}

            {links?.googleScholar && (
              <li>
                <LinkComponent
                  href={links.googleScholar}
                  aria-label="Google Scholar"
                  testId="google-scholar"
                >
                  <i className="ai ai-google-scholar" />
                </LinkComponent>
              </li>
            )}

            {links?.linkedin && (
              <li>
                <LinkComponent
                  href={links.linkedin}
                  aria-label="LinkedIn"
                  testId="linkedin"
                >
                  <i className="bx bxl-linkedin-square"></i>
                </LinkComponent>
              </li>
            )}

            {links?.other && (
              <li>
                <LinkComponent href={links.other} testId="other">
                  <i className="bx bx-laptop"></i>
                </LinkComponent>
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  )
}
