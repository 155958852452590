import content from '../../content/education'
import './EducationInsights.scss'

const {
  insights: { primary, secondary, tertiary },
  image: { src, alt },
} = content.educationInsights

export default function EducationInsights() {
  const insights = [primary, secondary, tertiary]

  return (
    <section className="EducationInsights" data-testid="education-insights">
      <div className="EducationInsights__container">
        <ul className="EducationInsights__cards">
          {insights.map((insight) => (
            <li
              key={`${insight.title}-${insight.total}`}
              className="EducationInsights__card"
              data-testid="education-insight"
            >
              <h2>
                <span className="EducationInsights__total">
                  {insight.total}
                </span>
                <span className="EducationInsights__title">
                  {insight.title}
                </span>
              </h2>
            </li>
          ))}
        </ul>
        <div className="EducationInsights__image">
          <img src={src} alt={alt} data-testid="education-insights-image" />
        </div>
      </div>
    </section>
  )
}
