import content from '../../content/education'
import './EducationPartners.scss'

const { title, partners } = content.educationPartners

export default function EducationPartners() {
  return (
    <section id="education-partners" className="EducationPartners">
      <div className="EducationPartners__container">
        <h2>{title}</h2>
        <ul className="EducationPartners__list">
          {partners.map((partner, i) => (
            <li
              key={`${partner.logo}-partner-${i}`}
              className="EducationPartners__item"
            >
              <img loading="lazy" src={partner.logo} alt={partner.name} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
