import { useRef, useState } from 'react'
import Slider, { Settings } from 'react-slick'
import Image from '../Image/Image'
import './TestimonialCarousel.scss'

export default function TestimonialCarousel({
  title,
  testimonials,
}: {
  title?: string
  testimonials: {
    quote: string
    author: {
      name: string
      affiliation: string
      image: string
    }
  }[]
}) {
  const sliderRef = useRef<Slider>(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  const slickSettings: Settings = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    beforeChange: (_oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  return (
    <section className="Testimonials">
      <div className={`Testimonials__container ${!title ? 'pt-10' : ''}`}>
        {title && <h3 className="text-h3 mb-3 text-center pt-10">{title}</h3>}
        <Slider
          {...slickSettings}
          ref={sliderRef}
          className="Testimonials__slider"
        >
          {testimonials.map((quote, index) => (
            <Testimonial key={index} quote={quote} />
          ))}
        </Slider>
        <div className="TestimonialCarousel__controls">
          <button
            aria-label="previous slide"
            className="TestimonialCarousel__control"
            onClick={() => sliderRef.current?.slickPrev()}
          >
            <i className="bx bx-chevron-left" />
          </button>
          <div className="TestimonialCarousel__dots">
            {testimonials.map((_, index) => (
              <button
                key={`feature-dot-${index}`}
                aria-label={`go to slide ${index}`}
                onClick={() => sliderRef.current?.slickGoTo(index)}
                className={`TestimonialCarousel__dot ${
                  currentSlide === index ? 'active' : ''
                }`}
              >
                <i className="bx bxs-circle text-[8px]"></i>
              </button>
            ))}
          </div>
          <button
            aria-label="next slide"
            className="TestimonialCarousel__control"
            onClick={() => sliderRef.current?.slickNext()}
          >
            <i className="bx bx-chevron-right" />
          </button>
        </div>
      </div>
    </section>
  )
}

const Testimonial = ({
  quote,
}: {
  quote: {
    quote: string
    author: {
      name: string
      affiliation: string
      image: string
    }
  }
}) => {
  return (
    <div key={quote.quote} className="Testimonials__card">
      <div className="Testimonials__card-content">
        <div className="Testimonials__image">
          <Image src={quote.author.image} alt={quote.author.name} />
        </div>
        <div>
          <p className="Testimonials__quote">“{quote.quote}”</p>
          <div className="Testimonials__author">
            <div className="Testimonials__author-info">
              <h3>{quote.author.name}</h3>
              <p>{quote.author.affiliation}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
