import content from '../../content/education'
import LinkComponent from '../LinkComponent/LinkComponent'
import './EducationWhySection.scss'

const { title, cards, description, cta } = content.whyEducatorsSection

export default function EducationWhySection() {
  return (
    <section
      className="EducationWhySection"
      data-testid="education-why-section"
    >
      <div className="container">
        <h2>{title}</h2>
        <ul className="EducationWhySection__cards">
          {cards.map((card, index) => (
            <li
              key={index}
              className="WhyEducationSection__card"
              data-testid="why-education-section-card"
            >
              <div>
                <img src={card.icon} alt="" />
              </div>
              <h3>{card.title}</h3>
            </li>
          ))}
        </ul>
        <div className="EducationWhySection__cta">
          {description && <p>{description}</p>}
          <div className="flex justify-center">
            <LinkComponent
              className="Button__primary--blue min-w-[200px]"
              href={cta.link}
            >
              {cta.text}
            </LinkComponent>
          </div>
        </div>
      </div>
    </section>
  )
}
