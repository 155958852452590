import MarkdownText from '../../MarkdownText/MarkdownText'

import './FormLayout.scss'

type FormSuccessMessageProps = {
  formSuccessTitle: string
  formSuccessText: string
}

type FormLayoutProps = {
  /* Attributes */
  id: string
  className?: string
  /* Content */
  title: string
  description: string
  footnoteMarkdown: string
  image: string
  /* Form Properties */
  formName: string
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  isSubmitted: boolean
  isSubmitting: boolean
  isSubmissionError: boolean
  submissionErrorText: string
  /* Form Fields */
  children: React.ReactNode
} & FormSuccessMessageProps

export default function FormLayout({
  id,
  className = '',
  title,
  description,
  image,
  footnoteMarkdown,
  formName,
  onSubmit,
  isSubmitted,
  isSubmitting,
  isSubmissionError,
  submissionErrorText,
  children: formFields,
  formSuccessTitle,
  formSuccessText,
}: FormLayoutProps) {
  return (
    <section
      id={id}
      className={`FormLayout ${className}`}
      data-testid="form-layout"
    >
      <div className="FormLayout__container">
        <div className="FormLayout__form">
          <form
            onSubmit={onSubmit}
            name={formName}
            className={isSubmitted ? 'hidden' : ''}
          >
            <h2 className="text-h2">{title}</h2>
            <h3 className="text-body max-w-[400px] mx-auto">{description}</h3>
            <div className="py-6">
              {formFields}
              <div className="col-span-2 flex justify-center mt-1">
                <button
                  type="submit"
                  className="Button__primary--yellow text-button min-w-[200px] w-full md:!w-auto disabled:!bg-grey-6 disabled:cursor-not-allowed disabled:text-grey-4 hover:opacity-80 focus-visible:opacity-80"
                  disabled={isSubmitting || isSubmitted}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>

          {isSubmitted && (
            <SuccessMessage
              formSuccessTitle={formSuccessTitle}
              formSuccessText={formSuccessText}
            />
          )}

          <div
            data-testid="form-layout-error"
            className={`text-error-4 text-center ${
              !isSubmissionError ? 'hidden' : ''
            }`}
          >
            <p className="flex items-center justify-center gap-2">
              <i className="bx bxs-error-circle"></i>{' '}
              <span>{submissionErrorText}</span>
            </p>
          </div>

          <MarkdownText
            className={`FormLayout__privacy ${isSubmitted ? 'hidden' : ''}`}
            text={footnoteMarkdown}
          />
        </div>
        <div className="FormLayout__image">
          <img src={image} alt="" />
        </div>
      </div>
    </section>
  )
}

const SuccessMessage = ({
  formSuccessTitle,
  formSuccessText,
}: FormSuccessMessageProps) => {
  return (
    <div className="FormLayout__success" data-testid="form-layout-success">
      <div className="mx-auto max-w-[457px]">
        <i className="bx bx-check-circle text-4xl text-primary-green-4 pb-4 "></i>
        <h2 className="text-h2">{formSuccessTitle}</h2>
        <MarkdownText
          className="FormLayout__success-text"
          text={formSuccessText}
        />
      </div>
    </div>
  )
}
