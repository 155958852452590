import Seo from '../components/seo'
import TestimonialCarousel from '../components/TestimonialCarousel/TestimonialCarousel'
import EducationHero from '../components/EducationHero/EducationHero'
import EducationWhySection from '../components/EducationWhySection/EducationWhySection'
import EducationInsights from '../components/EducationInsights/EducationInsights'
import EducationContact from '../components/EducationContact/EducationContact'
import LinkComponent from '../components/LinkComponent/LinkComponent'
import InformationCard from '../components/InformationCard/InformationCard'
import QuantumEducators from '../components/QuantumEducators/QuantumEducators'
import EducationPartners from '../components/EducationPartners/EducationPartners'
import educationContent from '../content/education'

const {
  meta_title,
  meta_description,
  meta_image,
  explorePennyLane,
  testimonials_section,
} = educationContent

const Education = () => {
  return (
    <div className="Education">
      <EducationHero />
      <EducationWhySection />
      <EducationPartners />
      <EducationInsights />
      <EducationContact />
      <QuantumEducators />
      <TestimonialCarousel testimonials={testimonials_section.quotes} />

      <section className="py-10">
        <div className="container">
          <h2 className="text-center text-h3 pb-6">{explorePennyLane.title}</h2>
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md: gap-6">
            {explorePennyLane.cards.map((card) => (
              <li key={`explore-card-${card.title}`}>
                <LinkComponent href={card.link}>
                  <InformationCard
                    title={card.title}
                    description={card.description}
                    icon={card.icon}
                  />
                </LinkComponent>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </div>
  )
}

export default Education

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}
