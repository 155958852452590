import { useState } from 'react'
import { Input, Select } from 'component-library'
import content from '../../content/education'
import FormLayout from '../FormLayouts/FormLayout/FormLayout'
import useForm from '../../hooks/useForm'

/* Form State */
type FormValues = {
  firstName: string
  lastName: string
  institution: string
  email: string
  role: string
}

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  institution: '',
  email: '',
  role: '',
}

interface IOption {
  label: string
  value: string
}
const GOOGLE_SHEET_URL = `https://script.google.com/macros/s/AKfycbxodXrEHMjbPXprzXjfZhX4CzgCmrPzfB9VbBQ1NxAZU4alB6Hm7N7SX-4x5P_91C44/exec`
const FORM_NAME = 'education-contact'

const { getInTouchSection } = content

export default function EducationContact() {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submittingForm, setSubmittingForm] = useState(false)
  const [showFormSubmissionError, setShowFormSubmissionError] = useState(false)

  const { values, handleChange, handleSubmit } = useForm<FormValues>({
    initialValues,
  })

  const submitEducationForm = async (values: FormValues) => {
    const formData = new FormData()
    formData.append('firstName', values.firstName)
    formData.append('lastName', values.lastName)
    formData.append('institution', values.institution)
    formData.append('email', values.email)
    formData.append('role', values.role)

    setShowFormSubmissionError(false)
    setSubmittingForm(true)

    try {
      const response = await fetch(GOOGLE_SHEET_URL, {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        setIsSubmitted(true)
      } else {
        setShowFormSubmissionError(true)
      }
    } catch (error) {
      setShowFormSubmissionError(true)
    }

    setSubmittingForm(false)
  }

  return (
    <FormLayout
      id="get-in-touch"
      className="EducationContact"
      title={getInTouchSection.title}
      description={getInTouchSection.description}
      footnoteMarkdown={getInTouchSection.footnote}
      image={getInTouchSection.image}
      formName={FORM_NAME}
      onSubmit={handleSubmit(submitEducationForm)}
      isSubmitted={isSubmitted}
      isSubmitting={submittingForm}
      isSubmissionError={showFormSubmissionError}
      submissionErrorText={getInTouchSection.formErrorMessage}
      formSuccessTitle={getInTouchSection.formSubmittedTitle}
      formSuccessText={getInTouchSection.formSubmittedText}
    >
      <div className="grid gap-2">
        <div className="flex flex-col md:flex-row col-span-2 gap-2">
          <Input
            className="w-full md:w-1/2"
            fieldLabelHidden
            required
            formName={FORM_NAME}
            disabled={submittingForm}
            fieldName="firstName"
            placeholder="First name"
            fieldLabel="First name"
            updateValue={(value) => handleChange('firstName', value)}
            value={values?.firstName || ''}
          />
          <Input
            className="w-full md:w-1/2"
            fieldLabelHidden
            required
            formName={FORM_NAME}
            disabled={submittingForm}
            fieldName="lastName"
            placeholder="Last name"
            fieldLabel="Last name"
            updateValue={(value) => handleChange('lastName', value)}
            value={values?.lastName || ''}
          />
        </div>

        <Input
          className="col-span-2 md:col-span-1"
          fieldLabelHidden
          type="email"
          required
          formName={FORM_NAME}
          disabled={submittingForm}
          fieldName="email"
          placeholder="Academic email"
          fieldLabel="Email"
          updateValue={(value) => handleChange('email', value)}
          value={values?.email || ''}
        />
        <Input
          className="col-span-2 md:col-span-1"
          fieldLabelHidden
          required
          formName={FORM_NAME}
          disabled={submittingForm}
          fieldName="institution"
          placeholder="Affiliated institution"
          fieldLabel="Affiliated institution"
          updateValue={(value) => handleChange('institution', value)}
          value={values?.institution || ''}
        />
        <div className="col-span-2 mb-1">
          <Select
            className="w-full text-brand-yellow-2"
            fieldLabel="Academic role"
            fieldLabelHidden
            fieldName="academicRole"
            formName={FORM_NAME}
            disabled={submittingForm}
            options={getInTouchSection.dropdownOptions}
            placeholder={
              <div>
                <span className="text-[#6F767B] text-base">Academic role</span>
              </div>
            }
            onChange={(newValue) => {
              const newValueLocal = newValue as IOption
              handleChange('role', newValueLocal.value)
            }}
          />
        </div>
      </div>
    </FormLayout>
  )
}
