import { useMemo } from 'react'
import Slider, { Settings } from 'react-slick'
import QuantumEducatorCard from './QuantumEducatorCard'
import content from '../../content/education'
import './QuantumEducators.scss'

const { title, educators } = content.educatorsSection

export default function QuantumEducators() {
  /** Note:
   * - Sorts educators by last name (A-Z) and groups them into pages of 3
   * - Function is memoized to prevent this expensive operation from happening on every render.
   */
  const educatorsPages = useMemo(() => {
    const orderedEducators = [...educators].sort((a, b) =>
      a.lastName.localeCompare(b.lastName)
    )

    return Array.from({ length: Math.ceil(educators.length / 3) }, (_, i) =>
      orderedEducators.slice(i * 3, i * 3 + 3)
    )
  }, [educators])

  const settings: Settings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    infinite: true,
    dots: true,
    arrows: true,
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: educatorsPages.length > 1,
        },
      },
    ],
  }

  return (
    <section className="QuantumEducators">
      <div className="QuantumEducators__container">
        <h2>{title}</h2>
        <Slider {...settings} className="QuantumEducators__slider">
          {educatorsPages.map((educators, pageIndex) => (
            <div
              key={`educators-page-${pageIndex}`}
              className="QuantumEducators__slide"
            >
              <div className="QuantumEducators__slide-container">
                {educators.map((educator, educatorIndex) => (
                  <QuantumEducatorCard
                    key={`${educator.firstName}-${educator.lastName}-${educatorIndex}`}
                    firstName={educator.firstName}
                    lastName={educator.lastName}
                    institution={educator.affiliation}
                    image={educator.image}
                    links={{
                      profile: educator.links.profile || '',
                      googleScholar: educator.links.googleScholar || '',
                      linkedin: educator.links.linkedin || '',
                      other: educator.links.other || '',
                    }}
                  />
                ))}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}
